export enum InputVariant {
  default = 'default',
  withIcon = 'with-icon',
  withIconSmall = 'with-icon small',
  withLabel = 'with-label'
}

export enum InputType {
  search = 'search',
  text = 'text',
  email = 'email',
  password = 'password',
  date = 'date',
  time = 'time'
}
